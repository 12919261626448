import * as Yup from 'yup';
import { Message } from 'yup/lib/types';

declare module 'yup' {
  export interface StringSchema extends Yup.BaseSchema {
    validateZIP(message?: string): StringSchema;
  }
}

const DEFAULT_MESSAGE = 'Your zip is not valid';
const LENGTH_MESSAGE = 'Zip code must be 5 digits';
const ZIP_REGEX = /^\d+$/;

Yup.addMethod<Yup.StringSchema>(Yup.string, 'validateZIP', function (message: Message = DEFAULT_MESSAGE) {
  return this.length(5, LENGTH_MESSAGE).test('validate-zip', message, function (textToValidate?: string) {
    if (!textToValidate) {
      return true;
    }

    const match = ZIP_REGEX.exec(textToValidate);

    return Boolean(match);
  });
});
